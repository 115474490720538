import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './Home/Home'
import Detail from './Detail/Detail';
import Nav from './Nav/Nav';
function App() {
  return (
    <Router>
       <Routes>

       <Route path="/" element={
       <>
    <Home />
    </>
} />

<Route path="/detail/:id" element={
       <>
      <Nav/>
    <Detail/>
    </>
} />
       

       </Routes>
    </Router>
  );
}

export default App;
