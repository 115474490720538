import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getDatabase, ref, get } from "firebase/database";
import { app } from '../firebaseConf';
import './Detail.css'
export default function Detail() {
  const { id } = useParams();
  const [etudiant, setEtudiant] = useState({});

  const fetchEtudiant = async (etudiantId) => {
    const db = getDatabase(app);
    const dbRef = ref(db, `etudiants/${etudiantId}`);
    const snapshot = await get(dbRef);
    if (snapshot.exists()) {
      setEtudiant(snapshot.val());
    } else {
      alert("Étudiant non trouvé");
    }
  };

  useEffect(() => {
    fetchEtudiant(id);
  }, [id]);

  return (
    <div>
      <br />
      <h3>{etudiant.etudiantNom} {etudiant.etudiantPrenom} ({etudiant.etudiantFiliere})</h3>
      <div className="card shadow mb-4">
        <div className="card-body">
          <DataTable notes={etudiant.notes ? Object.values(etudiant.notes) : []} />
        </div>
      </div>
    </div>
  );
}

const DataTable = ({ notes }) => (
  <div className="table-responsive">
    <table className="table table-bordered">
      <thead>
        <tr>
          <th>Année</th>
          <th>Fichier</th>
          <th>Télécharger</th> {/* Nouvelle colonne pour le téléchargement */}
        </tr>
      </thead>
      <tbody>
        {notes.length > 0 ? (
          notes.map((note, index) => (
            <tr key={index}>
              <td>{note.annee}</td>
              <td>{note.fichier}</td>
              <td>
                {/* Lien de téléchargement pour chaque fichier */}
                <a href={note.fichier} download={note.fichier.split('/').pop()} className="btn btn-primar">
                  Télécharger
                </a>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="3">Aucune note trouvée</td>
          </tr>
        )}
      </tbody>
    </table>
  </div>
);
