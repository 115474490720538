import React from 'react'
import './Nav.css'
import { Link } from 'react-router-dom'
export default function Nav() {
  return (
    <div>
    <nav className="navbar navbar-expand-md fixed-top navbar-dark bg-dar">
    <Link to='/'>
    <img className="mr-3" src={require('./img/aeeig logo-1.png')} alt="" width="48" height="48"/>
    </Link>
    <a className="navbar-brand" href="#">Association des Étudiants Ivoiriens en Guinée</a>

  </nav>
    </div>
  )
}
